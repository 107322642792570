import { Icon } from "design-react-kit";
import React from "react";
import { HeaderGroup } from "react-table";

type Props = {
  headerGroups: Array<HeaderGroup<any>>;
};

const TableHeader = ({ headerGroups }: Props) => (
  <thead>
    {headerGroups.map((headerGroup, i) => (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        key={i}
        style={{
          backgroundColor: "#F8F9F9",
          borderBottom: "1px solid #5A6772"
        }}
      >
        {headerGroup.headers.map((column, i) => (
          <th
            {...column.getHeaderProps(column.getSortByToggleProps())}
            key={i}
            className="px-6 py-2 text-sm font-weight-bold text-gray
                    text-uppercase"
          >
            {column.render("Header")}
            <span>
              {column.canSort && (
                <>
                  {column.isSorted ? (
                    <>
                      {column.isSortedDesc ? (
                        <Icon
                          icon="it-arrow-up-triangle"
                          style={{ color: "#5C6F82" }}
                        />
                      ) : (
                        <Icon
                          icon="it-arrow-down-triangle"
                          style={{ color: "#5C6F82" }}
                        />
                      )}
                    </>
                  ) : (
                    <Icon
                      icon="it-arrow-up-triangle"
                      style={{ color: "#5C6F82" }}
                    />
                  )}
                </>
              )}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

export default TableHeader;
